import { format } from "date-fns"
import { graphql, Link } from "gatsby"
import React from "react"
import { Button } from "../components/button"
import { Layout } from "../components/layout"
import logo from "../images/logo.png"

export const IndexPage: React.FC<any> = ({ data }) => {
  const { competitions } = data

  const [latestCompetition, ...pastCompetitions] = competitions.edges

  const formatDate = (date: Date) => {
    return format(date, "iii do LLL yyyy")
  }

  const startDate = formatDate(
    new Date(Date.parse(latestCompetition.node.data.start_date))
  )
  const endDate = formatDate(
    new Date(Date.parse(latestCompetition.node.data.end_date))
  )

  return (
    <Layout
      header={{ hideLogo: true, latestButton: false }}
      competitions={competitions}
      includeSponsers={true}
    >
      <div className="bg-green pb-2">
        <div className="text-center container mx-auto p-3 w-10/12 max-w-md">
          <img src={logo} width="350" />
        </div>
      </div>

      <div className="relative px-5 lg:px-0 container mx-auto ">
        <div className="md:absolute w-full mt-8 container flex flex-row h-full">
          <div className="w-full md:w-2/4 md:mx-auto md:my-auto text-center md:bg-white md:rounded md:py-5 bg-opacity-30">
            <div className="">
              <h1 className="font-primary font-black text-3xl">
                {latestCompetition.node.data.title.text}
              </h1>
              <time>
                {startDate} - {endDate}
              </time>
            </div>

            <div className="mt-4">
              <Button
                textColor="white"
                color="green"
                to={`/competition/${latestCompetition.node.uid}/latest-updates`}
              >
                Enter
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-8 hidden md:block">
          <img src={latestCompetition.node.data.banner_image.url} />
        </div>
      </div>

      <div className="mt-8 mx-auto container px-5 lg:px-0">
        <div
          className="cmsContent"
          dangerouslySetInnerHTML={{
            __html: latestCompetition.node.data.description?.html,
          }}
        />

        {pastCompetitions.length ? (
          <>
            <h2 className="mt-8 font-primary font-black text-3xl">
              Previous competitions
            </h2>

            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {pastCompetitions.map(competition => (
                <div className="relative">
                  <Link to={`/competition/${competition.node.uid}`}>
                    <img
                      src={competition.node.data.card_image.url}
                      style={{ width: "100%" }}
                    />

                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 text-white font-primary text-center flex flex-col justify-center">
                      <p>{competition.node.data.title.text}</p>
                      <time>
                        {formatDate(new Date(competition.node.data.start_date))}
                      </time>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    competitions: allPrismicCompetition(
      sort: { fields: data___start_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          first_publication_date
          data {
            body1 {
              ...Charities
            }
            title {
              text
            }
            description {
              html
            }
            start_date
            end_date
            banner_image {
              url
            }
            card_image {
              url
            }
          }
        }
      }
    }
  }

  fragment Charities on PrismicCompetitionBody1Charity {
    primary {
      charity_name {
        text
      }
      logo {
        url
      }
    }
  }
`
